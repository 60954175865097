import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import * as styles from './Contact.module.scss';

const Contact = ({ data }) => {
	return (
		<div className={styles.wrapper}>
			<Container>
				<Row className="justify-content-center">
					<Col xs={12} lg={10}>
						<h2 className={styles.title}>{data.title}</h2>
						<p className={styles.text}>{data.text}</p>
						<a className={styles.link} href={`mailto:${data.email}`} aria-label="Email us">{data.email}</a>
					</Col>
				</Row>
			</Container>
		</div>
	)
}

export default Contact;
