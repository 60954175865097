import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import * as styles from "./MediaAndText.module.scss";

const MediaAndText = ({ data }) => {
  return (
    <div className={styles.wrapper}>
      <Container>
        <Row>
          <Col xs={12}>
            <div className={styles.background}>
              <Row className="justify-content-center">
                <Col xs={12} md={6} lg={4} className="order-md-2 offset-lg-1">
                  {data.media.data ? (
                    <Row>
                      {data.media.data.map((mediaItem, index) => {
                        return (
                          <Col xs={6} md={12} key={index}>
                            <figure>
                              <img
                                className="img-fluid w-100"
                                alt={mediaItem.attributes.alternativeText}
                                width={mediaItem.attributes.width}
                                height={mediaItem.attributes.height}
                                src={mediaItem.attributes.url}
                              />
                            </figure>
                          </Col>
                        );
                      })}
                    </Row>
                  ) : (
                    ``
                  )}
                </Col>
                <Col xs={12} md={6} lg={5} className="order-md-1">
                  <h2 className={`${styles.title} mb-4`}>{data.title}</h2>
                  <div className={styles.text}>
                    <ReactMarkdown
                        children={data.paragraph}
                        rehypePlugins={[rehypeRaw]}
                        remarkPlugins={[remarkGfm]}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MediaAndText;
