import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import * as styles from "./Summary.module.scss";

const Summary = ({ data }) => {
  const date = new Date(data.startDate);
  const dateOptions = {
    day: `numeric`,
    month: `long`,
    year: `numeric`,
  };

  const items = [
    {
      label: "Salary",
      title: data.salary,
      info: data.salarySubtitle,
    },
    {
      label: "Location",
      title: data.location,
      info: data.locationSubtitle,
    },
    {
      label: "Start date",
      title: date.toLocaleString("en-gb", dateOptions),
      info: data.startDateSubtitle,
    },
  ];

  return (
    <div className={styles.wrapper}>
      <Container>
        <Row>
          {items.map((item, index) => {
            return (
              <Col xs={12} md={4} key={index}>
                <div className={styles.item}>
                  <h4 className={styles.itemLabel}>{item.label}</h4>
                  <p className={styles.itemTitle}>{item.title}</p>
                  {item.info ? (
                    <p className={styles.itemInfo}>{item.info}</p>
                  ) : (
                    ``
                  )}
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
};

export default Summary;
