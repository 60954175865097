import React from "react";
import Contact from "../career/Contact";
import MediaAndText from "../career/MediaAndText";
import Overview from "../career/Overview";

const componentsMap = {
  "career.contact": Contact,
  "career.media-and-text": MediaAndText,
  "career.overview": Overview,
};

const Block = ({ block }) => {
  const Component = componentsMap[block.type];

  if (!Component) {
    return null;
  }

  return <Component data={block.data} />;
};

const CareerBlocksRenderer = ({ blocks }) => {
  return (
    <>
      {blocks.map((block, index) => {
        return <Block key={index} block={block} />;
      })}
    </>
  );
};

export default CareerBlocksRenderer;
