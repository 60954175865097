import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout/Layout';
import Hero from '../components/hero/Hero';
import Summary from '../components/career/Summary';
import CareerBlocksRenderer from '../components/career-blocks-renderer/CareerBlocksRenderer';
import '../style/main.scss';

const Career = ({ pageContext: { page }, reporter }) => {
	const metaTitle = ["Ivo Digital", page.seo?.title].filter(Boolean).join(" - ");
	const metaDescription = page.seo?.description || "";
	const metaImage = page.seo?.imageUrl || "";
	const url = typeof window !== `undefined` ? window.location.href : ``;

	return (
		<Layout logoColour="yellow">
			<Helmet htmlAttributes={{ lang: `en` }}>
				<meta charSet="utf-8"/>
				<title>{metaTitle}</title>
				<link rel="canonical" href={url}/>
				<link rel={`description`} content={metaDescription}/>
				<meta property="og:title" content={metaTitle}/>
				<meta property="og:type" content="website"/>
				<meta property="og:url" content={url}/>
				<meta property="og:description" content={metaDescription}/>
				<meta property="og:image" content={metaImage}/>
				<meta property="og:site_name" content={metaTitle}/>
			</Helmet>
			<Hero
				title={page.title}
				subtitle={page.description}
			/>
			<Summary data={page} />
			<CareerBlocksRenderer blocks={page.blocks || []}/>
		</Layout>
	);
};

export default Career;
