import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ReactMarkdown from 'react-markdown';
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import * as styles from './Overview.module.scss';

const Overview = ({ data }) => {
	return (
		<div className={styles.wrapper}>
			<Container>
				<Row className="justify-content-center">
					<Col xs={12} md={6} lg={5}>
						<h2 className={styles.title}>{data.title}</h2>
						{data.applicationLink ? (
							<a
								className="btn btn-primary d-none d-md-inline"
								href={data.applicationLink}
								aria-label="Apply now"
							>Apply now</a>
						) : ``}
					</Col>
					<Col xs={12} md={6} lg={5}>
						<ReactMarkdown
							children={data.paragraph}
							className={styles.text}
							rehypePlugins={[rehypeRaw]}
							remarkPlugins={[remarkGfm]}
						/>
						{data.applicationLink ? (
							<a
								className="btn btn-primary d-md-none"
								href={data.applicationLink}
								aria-label="Apply now"
							>Apply now</a>
						) : ``}
					</Col>
				</Row>
			</Container>
		</div>
	)
}

export default Overview;
